import React from 'react';
import {
    List,
    Edit,
    EditButton,
    SimpleForm,
    Datagrid,
    TextField,
    Filter,
    FunctionField,
    TextInput,
    DateField
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import beautyCountry from "./helpers/beautyCountryRow";

const ManagerBookingFilter = props => (
    <Filter {...props}>
        <TextInput source="reference" label="Номер бронювання" alwaysOn/>
    </Filter>
);

const customerRenderer = record => record.customer ? `${record.customer.name} ${record.customer.surname} ${(record.country_code ? beautyCountry(record.country_code) : '')}` : null;

export const ManagerBookingList = props => (
    <List filters={<ManagerBookingFilter/>} exporter={false} {...props}>
        <Datagrid rowClick="show">
            <TextField source="reference" label="Номер бронювання"/>
            <DateField source="created_at" label="Дата бронювання" showTime/>
            <TextField source="check_in" label="Дата заїзду"/>
            <TextField source="check_out" label="Дата виїзду"/>
            <TextField source="nights" label="Ночей"/>
            <FunctionField label="Проживає гість" render={record => `${record.guest.name} ${record.guest.surname}`}/>
            <FunctionField label="Гість із країни"
                           render={record => `${(record.guest.country ? beautyCountry(record.guest.country) : '')}`}/>
            <FunctionField label="Власник аккаунту" render={customerRenderer}/>
            <TextField source="customer.email" label="Мейл власника"/>
            <TextField source="customer.phone" label="Телефон власника"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const ManagerBookingEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <RichTextInput source="note" label="Примітки"/>
        </SimpleForm>
    </Edit>
);