import React from 'react';
import {
    List,
    Edit,
    Datagrid,
    SimpleForm,
    TextField,
    TextInput,
    RichTextField,
    EditButton,
    Filter,
    ReferenceInput,
    DateInput,
    FunctionField,
    AutocompleteInput,
    Pagination,
    required
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import ContactsField from "./fields/ContactsField";
import ToggleableEnabledStateField from "./fields/TogglableField";
import DirectorySelectInput from "./input/DirectorySelectInput";
import {IsAdmin} from "./roleChecker";
import beautyCountry from "./helpers/beautyCountryRow";
import ExcelJS from "exceljs";

const countryRenderer = choice => (choice.name ? beautyCountry(choice.name) : null);


const HotelFilter = props => (
    <Filter {...props}>
        <TextInput source="title" label="Назва" alwaysOn/>
        <DirectorySelectInput source="type" dirname="hotel_types" label="Тип"/>
        <DirectorySelectInput source="provider" dirname="providers" label="Провайдер"/>
        <DateInput source="externalCreatedAt" label="Дата реєстрації готеля"/>
        <DirectorySelectInput source="stars" dirname="stars" label="Зірки"/>
        <ReferenceInput label="Місто" source="city" reference="hotels/cities"
                        filterToQuery={searchText => ({term: searchText})}>
            <AutocompleteInput optionText="name" helperText={false}/>
        </ReferenceInput>
        <ReferenceInput label="Країна" source="countryCode" reference="hotels/countries"
                        filterToQuery={searchText => ({term: searchText})}>
            <AutocompleteInput optionText={countryRenderer} helperText={false}/>
        </ReferenceInput>
    </Filter>
);


const exportData = posts => {
    const workBook = new ExcelJS.Workbook();
    const sheet = workBook.addWorksheet('My sheet');

    sheet.columns = [
        {
            header: 'Назва',
            key: 'hotelTitle',
            width: 30
        },
        {
            header: 'Тип',
            key: 'hotelType',
            width: 20
        },
        {
            header: 'Зірки',
            key: 'hotelStars',
            width: 10
        },            {
            header: 'Країна',
            key: 'hotelCountry',
            width: 20
        },            {
            header: 'Місто',
            key: 'hotelCity',
            width: 20
        },            {
            header: 'Адреса',
            key: 'hotelAddress',
            width: 30
        },
        {
            header: 'Мейл, Телефон',
            key: 'hotelMailPhone',
            width: 30
        },            {
            header: 'Особа',
            key: 'hotelContactPerson',
            width: 30
        },
        {
            header: "Якщо є 'галочка', то готель опубліковано на сайті",
            key: 'hotelEnabled',
            width: 10
        },
        {
            header: 'Провайдер',
            key: 'hotelProvider',
            width: 10
        },
        {
            header: 'Дата реєстрації готеля',
            key: 'externalCreatedAt',
            width: 10
        },
        {
            header: 'Примітки',
            key: 'hotelNote',
            width: 10
        },
        {
            header: 'Маржа',
            key: 'hotelExtraCharge',
            width: 10
        }
    ];

    posts.forEach(post => {
        sheet.addRow({
            hotelTitle: post.title,
            hotelType: post.type,
            hotelStars: post.stars,
            hotelCountry: (post.country_code ? beautyCountry(post.country_code) : null),
            hotelCity: post.city,
            hotelAddress: post.address,
            hotelMailPhone: (post.contacts ?`${post.contacts.mail} , ${post.contacts.phone}` : ''),
            hotelContactPerson: post.contact_person,
            hotelEnabled: post.enabled,
            hotelProvider: post.provider,
            externalCreatedAt: post.external_created_at,
            hotelNote: post.note,
            hotelExtraCharge: (post.extra_charge ? `${post.extra_charge.title}` : null)
        });
    });

    workBook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'Hotels.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
}


const HotelPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const HotelList = ({permissions, ...props}) => (
    <List filters={<HotelFilter/>} pagination={<HotelPagination />} {...props} exporter={exportData}>
        <Datagrid rowClick="">
            <TextField source="title" label="Назва"/>
            <TextField source="type" label="Тип"/>
            <TextField source="stars" label="Зірки"/>
            <FunctionField
                render={record => (record.country_code ? beautyCountry(record.country_code) : null)}
                label="Країна"/>
            <TextField source="city" label="Місто"/>
            <TextField source="address" label="Адреса"/>
            <ContactsField source="contacts" label="Мейл, Телефон"/>
            <TextField source="contact_person" label="Особа"/>
            <ToggleableEnabledStateField source="enabled" label="Якщо є 'галочка', то готель опубліковано на сайті" />
            <TextField source="provider" label="Провайдер"/>
            <FunctionField source="externalCreatedAt"
                           // render={record.external_created_at}
                           render={record => (record.external_created_at ? (new Date(record.external_created_at)).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null)}
                           label="Дата реєстрації готеля"/>
            <RichTextField source="note" label="Примітки"/>
            {
                IsAdmin(permissions) &&
                <FunctionField label="Маржа"
                               render={record => (record.extra_charge ? `${record.extra_charge.title}` : null)}/>
            }
            <EditButton label="Редагування"/>
        </Datagrid>
    </List>
);

export const HotelEdit = ({permissions, ...props}) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="contacts.email" label="Мейл" validate={required()}/>
            <TextInput source="contacts.phone" label="Телефон" validate={required()}/>
            <TextInput source="contact_person" label="Особа"/>
            <RichTextInput source="note" label="Примітки"/>
            {
                IsAdmin(permissions) &&
                <ReferenceInput label="Маржа" source="extra_charge.id" reference="extra-charges"
                                filterToQuery={searchText => ({title: searchText})} validate={required()}>
                    <AutocompleteInput optionText="title"/>
                </ReferenceInput>
            }
        </SimpleForm>
    </Edit>
);
