import React from 'react';
import {
    List,
    SearchInput,
    Edit,
    EditButton,
    SimpleForm,
    Datagrid,
    Filter,
    FunctionField,
    TextInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import beautyCountry from "./helpers/beautyCountryRow";

const ExcelJS = require('exceljs');

const countryRenderer = choice => (choice.name ? beautyCountry(choice.name) : null);

const AdminBookingFilter = props => (
    <Filter {...props}>
        <SearchInput source="reference" alwaysOn placeholder="Номер бронювання"/>
        <DateInput source="createdAt" label="Дата бронювання"/>
        <DateInput source="checkIn" label="Дата заїзду"/>
        <DateInput source="checkOut" label="Дата виїзду"/>
        <TextInput source="customer" label="Власник аккаунту"/>
        <ReferenceInput label="Країна гостя" source="guest_country" reference="bookings/guests/countries"
                        filterToQuery={searchText => ({term: searchText})}>
            <AutocompleteInput optionText={countryRenderer} helperText={false}/>
        </ReferenceInput>
    </Filter>
);

const exportData = posts => {
    const workBook = new ExcelJS.Workbook();
    const sheet = workBook.addWorksheet('My sheet');

    sheet.columns = [
        {
            header: 'Номер бронювання',
            key: 'hotelBookingUniqueNumber',
            width: 15
        },
        {
            header: 'Провайдер',
            key: 'provider',
            width: 15
        },
        {
            header: 'Дата бронювання',
            key: 'createdAt',
            width: 20
        },
        {
            header: 'Дата заїзду',
            key: 'checkIn',
            width: 15
        },
        {
            header: 'Дата виїзду',
            key: 'checkOut',
            width: 15
        },
        {
            header: 'Діб',
            key: 'totalNights',
            width: 10
        },
        {
            header: 'Власник аккаунту',
            key: 'customerName',
            width: 20
        },
        {
            header: 'Гість з країни',
            key: 'customerCountry',
            width: 15
        },
        {
            header: 'Гість з міста',
            key: 'customerCity',
            width: 15
        },
        {
            header: 'Будуть жити у номері',
            key: 'guestsCount',
            width: 15
        },
        {
            header: 'Сумма проживання, грн.',
            key: 'totalPrice',
            width: 15
        },
        {
            header: 'Статус бронювання',
            key: 'bookingStatus',
            width: 15
        }
    ];

    posts.forEach(post => {
        sheet.addRow({
            hotelBookingUniqueNumber: post.details.hotelBookingUniqueNumber,
            provider: post.hotel ? post.hotel.provider : '',
            createdAt: post.created_at,
            checkIn: post.check_in,
            checkOut: post.check_out,
            totalNights: post.details.totalNights,
            customerName: post.customer ? `${post.customer.name} ${post.customer.surname} ${(post.country_code ? beautyCountry(post.country_code) : '')}` : null,
            customerCountry: `${(post.customer.country ? beautyCountry(post.customer.country) : '')}`,
            customerCity: `${(post.customer.city ? beautyCountry(post.customer.city) : '')}`,
            guestsCount: post.details.hotelBookingInfo ? post.details.hotelBookingInfo[0].hotelBookingGuestsCount : '',
            totalPrice: post.details.hotelBookingTotalPrice,
            bookingStatus: post.details.hotelBookingStatus
        });
    });

    workBook.xlsx.writeBuffer().then(data => {
       const blob = new Blob([data], {
           type: 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet',
       });
       const url = window.URL.createObjectURL(blob);
       const anchor = document.createElement('a');
       anchor.href = url;
       anchor.download = 'Booking.xlsx';
       anchor.click();
       window.URL.revokeObjectURL(url);
    });
}


const reference = record => record.details.hotelBookingUniqueNumber;
const provider = record => record.hotel ? record.hotel.provider : '';
const guestsInRoom = record => record.details.hotelBookingInfo ? record.details.hotelBookingInfo[0].hotelBookingGuestsCount : '';
const bookingStatus = record => record.details.hotelBookingStatus;
const totalNights = record => record.details.totalNights;
const hotelBookingTotalPrice = record => record.details.hotelBookingTotalPrice;
const customerRenderer = record => record.customer ? `${record.customer.name} ${record.customer.surname} ${(record.country_code ? beautyCountry(record.country_code) : '')}` : null;
const createdAt = record => record.created_at;
const checkIn = record => record.check_in;
const checkOut = record => record.check_out;

export const AdminBookingList = props => (
    <List filters={<AdminBookingFilter/>} {...props} exporter={exportData} >
        <Datagrid rowClick="show">
            <FunctionField source="details.hotelBookingUniqueNumber" label="Номер бронювання" render={reference} />
            <FunctionField source="hotel.provider" label="Провайдер" render={provider} />
            <FunctionField source="createdAt" render={createdAt} label="Дата бронювання" />
            <FunctionField source="checkIn" label="Дата заїзду" render={checkIn} />
            <FunctionField source="checkOut" label="Дата виїзду" render={checkOut} />
            <FunctionField source="details.totalNights" label="Діб" render={totalNights} />
            <FunctionField source='customer.name' label="Власник аккаунту" render={customerRenderer} />
            <FunctionField source="customer.country" label="Гість з країни"
                           render={record => `${(record.customer.country ? beautyCountry(record.customer.country) : '')}`}/>
            <FunctionField source="customer.city" label="Гість з міста"
                           render={record => `${(record.customer.city ? beautyCountry(record.customer.city) : '')}`}/>
            <FunctionField source='details.hotelBookingInfo.hotelBookingGuestsCount' label="Будуть жити у номері" render={guestsInRoom}/>
            <FunctionField source="details.hotelBookingTotalPrice" label="Сумма проживання, грн." render={hotelBookingTotalPrice}/>
            <FunctionField source="status" label="Статус бронювання" render={bookingStatus}/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const AdminBookingEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <RichTextInput source="note" label="Примітки"/>
        </SimpleForm>
    </Edit>
);
