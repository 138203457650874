import React from 'react';
import {
    Error,
    Loading,
    useQuery
} from 'react-admin';
import beautyCountry from "./helpers/beautyCountryRow";

const CustomShow = props => {
    const {data, loading, error} = useQuery({
        type: 'getOneShow',
        resource: 'bookings',
        payload: {id: props.id}
    });
    if (loading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    return (
        <div>
            <h2>Booking id: {data.reference}</h2>
            <h2>Guest: {data.guest.name} {data.guest.surname}</h2>
            <h3>Status: {data.status}</h3>
            <div>
                <strong>{data.tariffs.length}</strong> number(s);
                <strong>{data.total_net} {data.currency}</strong>
            </div>
            <div><strong>Check in:</strong> {data.check_in}</div>
            <div><strong>Check out:</strong> {data.check_out}</div>
            <div><strong>Created at:</strong> {data.created_at}</div>
            <div>
                <strong>Hotel #{data.hotel.external_id}:</strong> {data.hotel.title} {data.hotel.stars} start(s)
                {beautyCountry(data.hotel.country_code)}, {data.hotel.address}
            </div>
        </div>
    );
};

export const BookingShow = (props) => (
    <CustomShow {...props}>
    </CustomShow>
);